<template>
    <v-card class="d-flex flex-column fill-height" flat>
        <!-- {{valid}} {{currentModel.PuntiVenditaInclusi.length}} {{currentModel.Clusters.length}} {{currentModel.PuntiVenditaEsclusi.length}} -->
        <v-card-text>
            <v-stepper v-model="step" v-if="ready" non-linear>
                <v-stepper-header>
                    <v-stepper-step step=1 editable :rules="[() => valid]"
                        >Contenuti</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" step=2 editable
                        >Importa Negozi</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 3" step=3 editable
                        >Seleziona Negozi</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 4" step=4 editable
                        >Seleziona Clusters</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step step=5 editable
                        >Escludi Negozi</v-stepper-step
                    >

                    <v-divider></v-divider>
                </v-stepper-header>
                <v-stepper-items class="steppers-container">
                    <v-stepper-content step=1>
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Promo
                                :model.sync="currentModel"
                                :valid.sync="valid"
                            ></Promo>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step=2>
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <ImportStores
                                :farmId="currentModel.FarmId"
                                :stores.sync="importedStores"
                            ></ImportStores>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step=3>
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Stores
                                :farmId="currentModel.FarmId"
                                :stores.sync="currentModel.PuntiVenditaInclusi"
                            ></Stores>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step=4>
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Clusters
                                :farmId="currentModel.FarmId"
                                :clusters.sync="currentModel.Clusters"
                            ></Clusters>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step=5>
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Stores
                                :farmId="currentModel.FarmId"
                                :stores.sync="currentModel.PuntiVenditaEsclusi"
                            ></Stores>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="step = step + 1" v-if="step !== 5">
                Continua
            </v-btn>
            <v-btn color="primary" @click="insertPromo" :disabled="!completed" v-if="step === 5">
                Inserisci
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../../store/actions/store";

export const Promo = () => import("../components/Promo");
export const ImportStores = () => import("../components/ImportStores");
export const Stores = () => import("../components/StoresSelector");
export const Clusters = () => import("../components/ClustersSelector");

export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    },

    components: {
        Promo,
        Stores,
        Clusters,
        ImportStores
    },

    data: () => ({
        ready: false,
        step: 1,
        valid: false,
        currentModel: {},
        importedStores: []
    }),

    computed: {
        ...mapState({
            loaded: state => state.storeModule.loaded
        }),

        completed() {
            return this.valid && (this.currentModel.PuntiVenditaInclusi.length > 0 || this.currentModel.Clusters.length > 0);
        }
    },

    watch: {
        step: {
            handler: function(newVal) {
                if (typeof this.step === 'string') {
                    console.log('converto in numero')
                    this.step = parseInt(this.step)
                }
                console.log(newVal, typeof this.step, typeof newVal)
                if (newVal === 3) {
                    // merge imported stores
                    // creo array merge con valori unici per evitare duplicati
                    const merged = [
                        ...new Set([
                            ...this.importedStores,
                            ...this.currentModel.PuntiVenditaInclusi
                        ])
                    ];
                    // console.log(this.importedStores, this.currentModel.PuntiVendita, merged.length)
                    this.currentModel.PuntiVenditaInclusi = merged;
                }
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES
        }),

        async getStores(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadStores(farmId);
                }
            } catch (err) {
                console.error(err);
            }
        },

        insertPromo() {
            this.$emit("saveData", this.currentModel);
        }
    },

    beforeMount() {
        this.currentModel = JSON.parse(JSON.stringify(this.model));
    },

    mounted() {
        this.$nextTick(async () => {
            this.ready = true;
            await this.getStores(this.model.FarmId);
        });
    }
};
</script>

<style scoped>
.steppers-container {
    height: 600px;
}
</style>
